import React, {useEffect} from "react";
import { Loader } from "rsuite";
import { Navigate } from "react-router-dom";
import { auth } from "../../firebase";

function Logout(props) {
  let history = Navigate();
  useEffect(() => {
    auth.signOut();
    history("/");
    window.location.reload(true);
  }, [history]);
  return (
    <div>
      <Loader center vertical size="md" />
    </div>
  );
}

export default Logout;
