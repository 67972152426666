import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { getFirestore, addDoc, collection, getDocs, doc, deleteDoc, setDoc, getDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDV84MZ1kNO1o19hKihpX6SIANPmNpwJcQ",
  authDomain: "gabsengineeringservices.firebaseapp.com",
  projectId: "gabsengineeringservices",
  storageBucket: "gabsengineeringservices.appspot.com",
  messagingSenderId: "188057844963",
  appId: "1:188057844963:web:f0e88c7f37d1b69a7419d6",
  measurementId: "G-GHD7GC4QLP"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const storage = { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject }
export const firestore = { getFirestore, addDoc, collection, getDocs, doc, deleteDoc, setDoc, getDoc }
