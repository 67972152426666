import React, { useState } from 'react';
import { Navbar, Nav, Button } from "rsuite"
import { HashLink } from 'react-router-hash-link';
import logoPlaceholder from '../assets/logo.png'
import { useEffect } from 'react';

function TopNavbar(props) {
  const [active, setActive] = useState('home')
  const [logo, setLogo] = useState(null)
  useEffect(()=>{
    setLogo(props.logo)
  },[props])
  return (
    <div>
      <Navbar>
    <Navbar.Brand href='' as={MyLink}>
      <div className="open-menu">
        <Button />
      </div>
      <img src={logo ?? logoPlaceholder} alt="logo" />
    </Navbar.Brand>
    <Nav pullRight  activeKey={active} onSelect={setActive}>
      <Nav.Item eventKey="home" href='#home' as={MyLink}>Home</Nav.Item>
      <Nav.Item eventKey="who-we-are" href='#who-we-are' as={MyLink}>About us</Nav.Item>
      <Nav.Item eventKey="services" href='#services' as={MyLink}>Services</Nav.Item>
      <Nav.Item eventKey="team" href='#team' as={MyLink}>Team</Nav.Item>
      <Nav.Item eventKey="gallery" href='#gallery' as={MyLink}>Gallery</Nav.Item>
      <Nav.Item eventKey="contact-us" href='#footer' as={MyLink}>Contact Us</Nav.Item>
      <Nav.Item eventKey="login" href='/login' as={MyLink}>Login</Nav.Item>
    </Nav>
  </Navbar>
    </div>
  );
}

const MyLink = React.forwardRef((props, ref) => {
  const { href, as, ...rest } = props;
  return <HashLink smooth to={href} as={as} ref={ref} {...rest}></HashLink>;
});

export default TopNavbar;