import React, {useEffect, useState} from 'react';
import { FlexboxGrid, Col, Modal, Schema, Form, Button } from 'rsuite';
import TextField from "../../components/TextField"
import { Image } from '@rsuite/icons';
import {storage, firestore} from "../../firebase"

const teamModel = Schema.Model({
  name: Schema.Types.StringType().isRequired('This field is required.'),
  position: Schema.Types.StringType().isRequired('this field is required'),
  description: Schema.Types.StringType().isRequired('This field is required'),
});
function Team(props) {
  const {ref, getDownloadURL, getStorage, uploadBytesResumable} = storage
  const {addDoc, collection, getFirestore, getDocs, doc, deleteDoc} = firestore
  const [addMember, setAddMember] = useState(false)
  const [members, setMembers] = useState([]);
  const [imgPrev, setImgPrev] = useState(null);
  const [profile, setProfile] = useState(null);
  const [content, setContent] = useState({});
  const db = getFirestore()
  const bucket = getStorage()
  useEffect(()=>{
    getDocs(collection(db, "team")).then(dataQuery=>{
      const team = dataQuery.docs.map(member=>{
        return {id: member.id, ...member.data()}
      })
      setMembers(team);
    }).catch(err =>{
      console.log(err)
    })
  },[])
  const imageUpload = (e) => {
    e.preventDefault();
    if (e.target.files[0] === undefined) return;
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => setImgPrev(reader.result);
    setProfile(e.target.files[0]);
  };
  const onChange = (e) =>{
    setContent(e)
  }
  const onDelete = async(id) =>{
    try {
      await deleteDoc(doc(db, "team", id))
      const all = [...members]
      const afterDelete = all.filter(member=> member.id !== id)
      setMembers(afterDelete)
    } catch (err) {
      console.log(err)
    }
  }
  const onSubmit = (e) =>{
    if(!e) return
    if (!profile) return
    const storageRef = ref(bucket, `team/${profile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, profile)
    uploadTask.on("state_changed", ()=>{}, (err)=>{
      console.log(err)
    },()=>{
      getDownloadURL(uploadTask.snapshot.ref).then( async url =>{
        try {
          const saveInfo = await addDoc(collection(db, "team"),{
            ...content, profile: url
          })
          if(saveInfo.id){
            let all = [...members, {id: saveInfo.id, ...content, profile:url}]
            setMembers(all);
            setAddMember(false)
          }
        } catch (err) {
          console.log(err)
        }
      })
    })
  }
  return (
    <div className='admin-team'>
      <div className="top-header">
        <h5>Team members</h5>
      </div>
      <div className="create-section">
        <button onClick={()=>setAddMember(true)}>+ Add Team Member</button>
      </div>
      <FlexboxGrid>
        {members.length !== 0 && members.map(member=>(
          <FlexboxGrid.Item as={Col} colspan={20} key={member.id}>
          <div className="team-member-wrapper">
            <img src={member.profile} alt="member-img" />
            <div className="info">
              <h5>{member.name}</h5>
              <p className='position'>{member.position}</p>
              <p className='description'>{member.description}</p>
              <div className="edit-buttons">
                <button className="edit">Edit</button>
                <button className="delete" onClick={()=>onDelete(member.id)}>Delete</button>
              </div>
            </div>
          </div>
        </FlexboxGrid.Item>
        ))}
      </FlexboxGrid>
      <Modal open={addMember} onClose={()=>setAddMember(false)}>
        <Modal.Header>
          <Modal.Title>ADD A NEW TEAM MEMBER</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-create">
            <div className="image-uploader">
              <label htmlFor="profile">
              {!imgPrev ? <Image /> : (
                <img
                  src={imgPrev}
                  alt="profile"
                />
              )}
              </label>
              <input
                type="file"
                accept="image/*"
                name="profile"
                id="profile"
                style={{ display:"none" }}
                onChange={imageUpload}
              />
            </div>
            <Form model={teamModel} onChange={onChange} onSubmit={onSubmit}>
              <TextField name="name" label="Name" />
              <TextField name="position" label="Position" />
              <TextField name="description" label="Description" />
              <Button appearance="primary" type="submit" color='green'>Save</Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Team;