import React, { useEffect, useState } from "react";
import "./styles.css";
import TopNavbar from "./TopNavbar";
import { firestore } from "../firebase";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import backgroundCover from "../assets/what_we_do_background.jpg"
import logoPlaceholder from "../assets/logo.png"

function Index(props) {
	const { getDocs, getFirestore, collection, getDoc, doc } = firestore;
	const db = getFirestore();
	const [services, setServices] = useState([]);
	const [team, setTeam] = useState([]);
	const [carousel, setCarousel] = useState([]);
	const [gallery, setGallery] = useState([]);
	const [loading, setLoading] = useState(true);
	const [logo, setLogo] = useState(null)
	useEffect(() => {
		getDocs(collection(db, "carousel")).then((response) => {
			const dbCarousel = response.docs.map((carou) => {
				return { id: carou.id, ...carou.data() };
			});
			setCarousel(dbCarousel);
		});
		getDocs(collection(db, "services")).then((response) => {
			const dbServices = response.docs.map((service) => {
				return { id: service.id, ...service.data() };
			});
			setServices(dbServices);
		});
		getDocs(collection(db, "team")).then((response) => {
			const dbTeam = response.docs.map((team) => {
				return { id: team.id, ...team.data() };
			});
			setTeam(dbTeam);
		});
		getDocs(collection(db, "gallery")).then((response) => {
			const dbGallery = response.docs.map((image) => {
				return { id: image.id, ...image.data() };
			});
			setGallery(dbGallery);
		});
		(async() => {
			try {
				const docRef = doc(db, "logo", "logo")
				const docSnapshot = await getDoc(docRef)
				if (docSnapshot.exists()) {
					const data = docSnapshot.data()
					setLogo(data["url"])
				}
			} catch(error) {
				console.log("No logo set")
			}
		})()
		setLoading(false);
	}, []);
	return (
		<div>
			<TopNavbar logo={logo} />
			{/* CAROUSEL */}
			<div className="carousel-wrapper" id="home">
				<Carousel
				autoPlay={true}
				swipeable={true}
				infiniteLoop={true}
				showThumbs={false}
				>
					{carousel.length !== 0 &&
					carousel.map((rotating) => (
						<div className="carousel-info" key={rotating.id}>
							<img src={rotating.image} alt="carousel-image" />
							<div className="description">
								<p>{rotating.description}</p>
							</div>
						</div>
					))}
				</Carousel>
			</div>
			{/* WHAT WE ARE */}
			<div className="who-we-are" id="who-we-are">
				<h5 className="title">Who we are</h5>
				<div className="who-we-are-container">
					<p className="introduction">
						Balg engineering services limited has an aggregate experience with
						certified and highly skilled engineers and technical teams that offer
						to our client’s satisfactory results and long-lasting solutions to
						their concerns/problems. Not only does our certified technical team
						specialize in the latest technologies and delivery of unmatched
						results, but we are also dedicated to learning and supporting your
						business as a whole by providing you with cost efficient solutions.
					</p>
					<div className="vision-mission">
						<div className="vision">
							<h6>Our Vision</h6>
							<p>
								To be a leading provider of Engineering services in Uganda with
								extreme professionalism.
							</p>
						</div>
						<div className="mission">
							<h6>Our Mission</h6>
							<p>
								To integrate available technologies and create sustainable
								solutions for the business problems of our clients.
							</p>
						</div>
					</div>
					<img src={backgroundCover} alt="background-cover" />
				</div>
				{/* <div className="who-we-are-wrapper">
					<div className="mission">
						<h6>Our Mission</h6>
						<p>
							To integrate available technologies and create sustainable
							solutions for the business problems of our clients.
						</p>
					</div>
					<div className="vision">
						<h6>Our Vision</h6>
						<p>
							To be a leading provider of Engineering services in Uganda with
							extreme professionalism.
						</p>
					</div>
        </div>
        <div className="core-values">
          <h6>Our core values</h6>
          <p>
            <span>Excellency: </span>We look for and promote innovativeness,
            proficiency and leadership in all aspects of Engineering services.
          </p>
          <p>
            <span>Integrity: </span>We embrace honesty in everything we do and
            are determined to adhere to ethical Business principles and good
            corporate governance at all times.
          </p>
          <p>
            <span>Innovation: </span>We continuously develop and apply
            creative solutions towards improved service delivery
          </p>
          <p>
            <span>Quality: </span>We provide the highest degree of excellence
            of our products
          </p>
          <p>
            <span>Loyalty: </span>We are faithful to all our clients
            commitments
          </p>
          <p>
            <span>Self-improvement: </span>We always do our level best to
            improve our knowledge and commitment to our clients
          </p>
        </div> */}
			</div>
			{/* SERVICES */}
			{services.length !== 0 && 
			<div className="services-wrapper" id="services">
				<h5 className="title">OUR SERVICES</h5>
				<div className="services-container">
					{services.map((service) => (
							<div className="service" key={service.id}>
								<img src={service.poster} alt="service-img" />
								<div className="info">
									<h6>{service.name}</h6>
									<p>{service.description}</p>
								</div>
							</div>
						))}
				</div>
			</div>}
			{/* TEAM */}
			{team.length !== 0 && 
			<div className="team-wrapper" id="team">
				<h5 className="title">OUR TEAM</h5>
				<div className="team-container">
					{team.map((member) => (
							<div className="member" key={member.id}>
								<div className="img-info">
									<img src={member.profile} alt="member-img" />
									<div className="member-info">
									<h6>{member.name}</h6>
									<p className="title">{member.position}</p></div>
								</div>
								<div className="description">
									<p className="description">{member.description}</p>
								</div>
							</div>
						))}
				</div>
			</div>}
			{/* GALLERY */}
			{gallery.length !== 0 && 
			<div className="gallery-wrapper" id="gallery">
				<h5 className="title">Our Gallery</h5>
				<div className="gallery-container">
							{gallery.map(image=>(
								<div className="img-container" key={image.id}>
									<img src={image.image} alt="gallery-image" />
									<p className="caption">{image.caption}</p>
								</div>
							))}
				</div>
			</div>}
			{/* FOOTER */}
			<div className="footer" id="footer">
				<div className="contacts">
					<div className="info">
						<img src={logo ?? logoPlaceholder} alt="logo" />
						<p className="title">BALG ENGINEERING SERVICES</p>
						<p className="moto">"Building dream Reality"</p>
					</div>
					<div className="navigator">
						<p className="head">Pages</p>
						<ul>
							<li>
								<a href="#about-us">About us</a>
							</li>
							<li>
								<a href="#services">Services</a>
							</li>
							<li>
								<a href="#team">Team</a>
							</li>
						</ul>
					</div>
					<div className="contact-info">
						<p className="head">Contacts</p>
						<ul>
							<li>
								<a href="mailto:info@balgengineeringservices.com">
									info@balgsengineeringservices.com
								</a>
							</li>
							<li>
								<a href="tel:(256) 703955894">(256) 703955894/705788697</a>
							</li>
							<li>
								<a href="#location" target="_blank" rel="noopener noreferrer">
									Old portbell road kampala Uganda.
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="copywrite">
					<div className="owner">
						&copy; 2022 Balg Engineering All rights reserved
					</div>
					<div className="creator">
						designed by{" "}
						<a
							href="https://ibirori.rw"
							target="_blank"
							rel="noopener noreferrer"
						>
							Class of IV
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Index;
