import React, { useState, useEffect, useContext } from "react";
import { FlexboxGrid, Col, Schema, Form, Button, Loader } from "rsuite";
import TextField from "../components/TextField";
import "./styles.css";
import { Navigate, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { UserContext } from "../UserProvider";
import {signInWithEmailAndPassword} from "firebase/auth"

const { StringType } = Schema.Types;
const model = Schema.Model({
  email: StringType("provide an email address")
    .isEmail()
    .isRequired("provide an email address"),
  password: StringType().isRequired(),
});
function Login(props) {
  let navigate = useNavigate()
  const [content, setContent] = useState({});
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  useEffect(() => {
    if (user) {
      setSuccess(true);
      navigate("/admin")
    }
  }, [user]);
  const onChange = (e) => {
    setContent(e);
  };
  const onSubmit = async (e) => {
    setLoading(true);
    if (!e)
      return
    try {
      await signInWithEmailAndPassword(auth, content.email, content.password);
      setSuccess(true);
      navigate("/admin")
    } catch (error) {
      // Alert.error("Failed to authenticate your credentials", 5000);
      setLoading(false);
    }
  };
  return (
    <div>
      <FlexboxGrid className="login" justify="center">
        <FlexboxGrid.Item
          colspan={12}
          as={Col}
          xs={24}
          md={12}
          sm={16}
        >
          {success ? (
            <div>
              <h3>Successfully logged in</h3>
            </div>
          ) : (
            <div className="login-container">
              <Form model={model} onChange={onChange} onSubmit={onSubmit}>
                <h3>Admin login</h3>
                <TextField name="email" label="Email" />
                <TextField name="password" label="Password" type="password" />
                {loading ? (
                  <Loader content="verifying information" />
                ) : (
                  <Button type="submit" color="green">
                    Login
                  </Button>
                )}
              </Form>
            </div>
          )}
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
}

export default Login;
