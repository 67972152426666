import React, {useEffect, useContext, useState} from 'react';
import { Sidenav, Nav, FlexboxGrid } from 'rsuite';
import { UserContext } from "../../UserProvider";
import Logout from "./Logout";
import { auth, storage, firestore, app } from "../../firebase";
import { Route, Link, useNavigate, Routes, Navigate } from 'react-router-dom';
import Team from './Team';
import RotatingImages from './RotatingImages';
import Services from "./Services"
import "./admin_styles.css"
import Gallery from './Gallery';
import logo from "../../assets/logo.png"
import { doc, setDoc } from 'firebase/firestore';

function Dashboard(props) {
    const navigate = useNavigate()
    const { user, pending } = useContext(UserContext);
    const [isAuthenticated, setAuthentication] = useState(true);
    const [expand, setExpand] = useState(true);
    const [active, setActive] = useState('home');
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (auth.currentUser) {
        setAuthentication(true);
        setLoading(false)
        } else {
        setAuthentication(false);
        navigate("/login")
        }
    }, [user]);
  return (
    <div>
        <FlexboxGrid>
            <FlexboxGrid.Item colspan={6}>
            <Sidenav>
            <Sidenav.Header className="sidenav-header">
                <div className="header-container">
                <p>Balg Engineering Services</p>
                </div>
            </Sidenav.Header>
            <Sidenav.Body>
                <Nav activeKey={active} onSelect={setActive}>
                <Nav.Item eventKey="home" href='/admin' as={MyLink}>
                    Dashboard
                </Nav.Item>
                <Nav.Item eventKey="carousel" href='/admin/carousel' as={MyLink}>
                    Images
                </Nav.Item>
                <Nav.Item eventKey="services" href='/admin/services' as={MyLink}>
                    Services
                </Nav.Item>
                <Nav.Item eventKey="team" href='/admin/team' as={MyLink}>
                    Team
                </Nav.Item>
                <Nav.Item eventKey="gallery" href='/admin/gallery' as={MyLink}>
                    Gallery
                </Nav.Item>
                </Nav>
            </Sidenav.Body>
            </Sidenav>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={18}>
            {loading ? 
            <div className="loading" style={{width:"50%", margin:'auto', display:'flex', justifyContent:'center', alignContent:'center'}}>
                <div class="divLoader">
                <svg className="svgLoader" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg>
                </div>
            </div> :
            <Routes>
                <Route path="carousel" element={<RotatingImages />} auth={isAuthenticated} />
                <Route path="services" element={<Services />} auth={isAuthenticated} />
                <Route path="logout" element={<Logout />} auth={isAuthenticated} />
                <Route path="team" element={<Team />} auth={isAuthenticated} />
                <Route path="gallery" element={<Gallery />} auth={isAuthenticated} />
                <Route index element={<Home />} />
            </Routes>}
            </FlexboxGrid.Item>
        </FlexboxGrid>
    </div>);
}
const MyLink = React.forwardRef((props, ref) => {
    const { href, as, ...rest } = props;
    return <Link to={href} as={as} ref={ref} {...rest}></Link>;
});
const ProtectedRoute = ({ element, path, auth}) => {
    if (!auth) {
        return <Navigate to="/login" />;
    }
    <Route element={element} path={path} />
};


const Home = () =>{
    const { ref, getDownloadURL, uploadBytesResumable, getStorage } = storage
    const { addDoc, collection, getFirestore, getDocs, getDoc } = firestore
    const db = getFirestore(app)
    const bucket = getStorage(app)
    const [logos, setLogos] = useState([])
    const [currentLogo, setCurrentLogo] = useState(null)
    const [logoPreview, setLogoPreview] = useState(null)
    const [saving, setSaving] = useState(false)

    useEffect(async () => {
        const docRef = doc(db, "logo", "logo")
        const docSnap = await getDoc(docRef)
        if(docSnap.exists()) {
            const data = docSnap.data()
            setCurrentLogo(data["url"])
        }
    },[])

    /**
     * Logo management
     * Only one logo in the db with an id of logo
     * @returns
     */
    const imageUpload = (e) => {
        e.preventDefault();
        if (e.target.files[0] === undefined) return;
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => setLogoPreview(reader.result);
        setCurrentLogo(e.target.files[0]);
    };

    const saveLogo = () => {
        if(currentLogo == null) { return }
        const storageRef = ref(bucket, `logo/${currentLogo.name}`);
        const uploadTask = uploadBytesResumable(storageRef, currentLogo)
        uploadTask.on("state_changed", ()=>{}, (err)=>{
            console.log(err)
        },()=>{
            getDownloadURL(uploadTask.snapshot.ref).then( async url =>{
                try {
                    await setDoc(doc(db, "logo", "logo"), {
                        url: url, active: true
                    }, { merge: true })
                    setLogoPreview(null)
                    setCurrentLogo(url)
                } catch (err) {
                console.log(err)
                }
            })
        })
    }
    return (
        <div className='admin-dashboard-wrapper'>
        <div className="dashboard-welcome-card">
            <h4>Welcome to the admin dashboard</h4>
        </div>
        <div className="dashboard-logo">
            <h5>Website Logo</h5>
            <div className="dashboard-logo-img-container">
            <img src={currentLogo ?? logo} alt="logo" />
            <div className="dashboard-change-logo-wrapper">
                {logoPreview && <div className="logo-preview">
                <img src={logoPreview} alt="logo" />
                <button onClick={saveLogo}>Save new Logo</button>
                </div>}
                <div>
                <label htmlFor="change-logo">Change</label>
                <input
                    type="file"
                    accept="image/*"
                    name="change-logo"
                    id="change-logo"
                    style={{ display:"none" }}
                    onChange={imageUpload}
                />
                </div>
            </div>
            </div>
        </div>
        </div>
    )
}

export default Dashboard;
