import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Index from './screens/Index';
import Dashboard from './screens/admin/Dashboard';
import 'rsuite/dist/rsuite.min.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { UserProvider } from "./UserProvider";
import Login from "./screens/Login"

function App() {
  return (
    <div className="App">
      <UserProvider>
      <Router>
        <Routes>
          <Route path="test" element={<Outlet />}>
            <Route index element={<TestOne />} />
            <Route path=":id" element={<TestTwo />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/admin/*" element={<Dashboard />} />
          <Route path="/" element={<Index />} />
        </Routes>
      </Router>
      </UserProvider>
    </div>
  );
}

const TestOne = () =>{
  return (
    <div>this is test one</div>
  )
}
const TestTwo = () =>{
  return (
    <p>THis is test 2</p>
  )
}
export default App;
