import React, { createContext, useState } from "react";
import { useEffect } from "react";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";

export const UserContext = createContext();
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [pending, setPending] = useState(true);
  const [events, sedd] = useState([])
  const updevents= () =>{}
  useEffect(() => {
    onAuthStateChanged(auth, (usr) => {
      if (usr) {
        setUser(usr);
      }
      setPending(false);
    });
  }, []);
  return (
    <UserContext.Provider value={{ user, pending, updevents }}>
      {children}
    </UserContext.Provider>
  );
};
