import React, {useEffect, useState} from 'react';
import { FlexboxGrid, Col, Modal, Schema, Form, Button } from 'rsuite';
import TextField from "../../components/TextField"
import { Image } from '@rsuite/icons';
import {storage, firestore, app} from "../../firebase"

const carouselModel = Schema.Model({
  caption: Schema.Types.StringType().isRequired('This field is required'),
});

function Gallery(props) {
  const {ref, getDownloadURL, getStorage, uploadBytesResumable} = storage
  const {addDoc, collection, getFirestore, getDocs, doc, deleteDoc} = firestore
  const [addCarousel, setAddCarousel] = useState(false)
  const [carousels, setCarousels] = useState([]);
  const [imgPrev, setImgPrev] = useState(null);
  const [poster, setPoster] = useState(null);
  const [content, setContent] = useState({});
  const db = getFirestore(app)
  const bucket = getStorage(app)
  useEffect(()=>{
    getDocs(collection(db, "gallery")).then(dataQuery=>{
      const allCarousel = dataQuery.docs.map(carousel=>{
        return {id: carousel.id, ...carousel.data()}
      })
      setCarousels(allCarousel);
    }).catch(err =>{
      console.log(err)
    })
  },[])
  const imageUpload = (e) => {
    e.preventDefault();
    if (e.target.files[0] === undefined) return;
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => setImgPrev(reader.result);
    setPoster(e.target.files[0]);
  };
  const onChange = (e) =>{
    setContent(e)
  }
  const onDelete = async(id) =>{
    try {
      // also delete image
      await deleteDoc(doc(db, "gallery", id))
      const all = [...carousels]
      const afterDelete = all.filter(carousel=> carousel.id !== id)
      setCarousels(afterDelete)
    } catch (err) {
      console.log(err)
    }
  }
  const onSubmit = (e) =>{
    if(!e) return
    if (!poster) return
    const storageRef = ref(bucket, `gallery/${poster.name}`);
    const uploadTask = uploadBytesResumable(storageRef, poster)
    uploadTask.on("state_changed", ()=>{}, (err)=>{
      console.log(err)
    },()=>{
      getDownloadURL(uploadTask.snapshot.ref).then( async url =>{
        try {
          const saveInfo = await addDoc(collection(db, "gallery"),{
            ...content, image: url
          })
          if(saveInfo.id){
            let all = [...carousels, {id: saveInfo.id, ...content, image: url}]
            setCarousels(all);
            setAddCarousel(false)
          }
        } catch (err) {
          console.log(err)
        }
      })
    })
  }
  return (
    <div className='admin-carousel'>
      <div className="top-header">
        <h5>Gallery</h5>
      </div>
      <div className="create-section">
        <button onClick={()=>setAddCarousel(true)}>+ Add New Image</button>
      </div>
      <FlexboxGrid>
        {carousels.length !== 0 && carousels.map(carousel=>(
          <FlexboxGrid.Item as={Col} colspan={8} key={carousel.id}>
          <div className="admin-service-wrapper">
            <img src={carousel.image} alt="service-img" />
            <div className="info">
              <p>{carousel.caption}</p>
              <div className="edit-buttons">
                <button className="edit">Edit</button>
                <button className="delete" onClick={()=>onDelete(carousel.id)}>Delete</button>
              </div>
            </div>
          </div>
        </FlexboxGrid.Item>
        ))}
      </FlexboxGrid>
      <Modal open={addCarousel} onClose={()=>setAddCarousel(false)}>
        <Modal.Header>
          <Modal.Title>ADD A NEW IMAGE</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-create">
            <div className="image-uploader">
              <label htmlFor="profile">
              {!imgPrev ? <Image /> : (
                <img
                  src={imgPrev}
                  alt="profile"
                />
              )}
              </label>
              <input
                type="file"
                accept="image/*"
                name="profile"
                id="profile"
                style={{ display:"none" }}
                onChange={imageUpload}
              />
            </div>
            <Form model={carouselModel} onChange={onChange} onSubmit={onSubmit}>
              <TextField name="caption" label="Caption" />
              <Button appearance="primary" type="submit" color='green'>Save</Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Gallery;