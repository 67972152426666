import React, {useEffect, useState} from 'react';
import { FlexboxGrid, Col, Modal, Schema, Form, Button } from 'rsuite';
import TextField from "../../components/TextField"
import { Image } from '@rsuite/icons';
import {storage, firestore} from "../../firebase"

const serviceModel = Schema.Model({
  name: Schema.Types.StringType().isRequired('This field is required.'),
  description: Schema.Types.StringType().isRequired('This field is required'),
});

function Services(props) {
  const {ref, getDownloadURL, getStorage, uploadBytesResumable} = storage
  const {addDoc, collection, getFirestore, getDocs, doc, deleteDoc} = firestore
  const [addService, setAddService] = useState(false)
  const [services, setServices] = useState([]);
  const [imgPrev, setImgPrev] = useState(null);
  const [poster, setPoster] = useState(null);
  const [content, setContent] = useState({});
  const db = getFirestore()
  const bucket = getStorage()
  useEffect(()=>{
    getDocs(collection(db, "services")).then(dataQuery=>{
      const allServices = dataQuery.docs.map(service=>{
        return {id: service.id, ...service.data()}
      })
      setServices(allServices);
    }).catch(err =>{
      console.log(err)
    })
  },[])
  const imageUpload = (e) => {
    e.preventDefault();
    if (e.target.files[0] === undefined) return;
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => setImgPrev(reader.result);
    setPoster(e.target.files[0]);
  };
  const onChange = (e) =>{
    setContent(e)
  }
  const onDelete = async(id) =>{
    try {
      await deleteDoc(doc(db, "services", id))
      const all = [...services]
      const afterDelete = all.filter(service=> service.id !== id)
      setServices(afterDelete)
    } catch (err) {
      console.log(err)
    }
  }
  const onSubmit = (e) =>{
    if(!e) return
    if (!poster) return
    const storageRef = ref(bucket, `services/${poster.name}`);
    const uploadTask = uploadBytesResumable(storageRef, poster)
    uploadTask.on("state_changed", ()=>{}, (err)=>{
      console.log(err)
    },()=>{
      getDownloadURL(uploadTask.snapshot.ref).then( async url =>{
        try {
          const saveInfo = await addDoc(collection(db, "services"),{
            ...content, poster: url
          })
          if(saveInfo.id){
            let all = [...services, {id: saveInfo.id, ...content, poster:url}]
            setServices(all);
            setAddService(false)
          }
        } catch (err) {
          console.log(err)
        }
      })
    })
  }
  return (
    <div className='admin-services'>
      <div className="top-header">
        <h5>Services</h5>
      </div>
      <div className="create-section">
        <button onClick={()=>setAddService(true)}>+ Add New Service</button>
      </div>
      <FlexboxGrid>
        {services.length !== 0 && services.map(service=>(
          <FlexboxGrid.Item as={Col} colspan={8} key={service.id}>
          <div className="admin-service-wrapper">
            <img src={service.poster} alt="service-img" />
            <div className="info">
              <h5>{service.name}</h5>
              <p>{service.description}</p>
              <div className="edit-buttons">
                <button className="edit">Edit</button>
                <button className="delete" onClick={()=>onDelete(service.id)}>Delete</button>
              </div>
            </div>
          </div>
        </FlexboxGrid.Item>
        ))}
      </FlexboxGrid>
      <Modal open={addService} onClose={()=>setAddService(false)}>
        <Modal.Header>
          <Modal.Title>ADD A NEW SERVICE</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-create">
            <div className="image-uploader">
              <label htmlFor="profile">
              {!imgPrev ? <Image /> : (
                <img
                  src={imgPrev}
                  alt="profile"
                />
              )}
              </label>
              <input
                type="file"
                accept="image/*"
                name="profile"
                id="profile"
                style={{ display:"none" }}
                onChange={imageUpload}
              />
            </div>
            <Form model={serviceModel} onChange={onChange} onSubmit={onSubmit}>
              <TextField name="name" label="Name" />
              <TextField name="description" label="Description" />
              <Button appearance="primary" type="submit" color='green'>Save</Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Services;